<ng-template>
  <div class="by-flex by-flex-between">
    <div class="by-mr-2 by-mt-10">
      <img [src]="message.icon" width="32" />
    </div>
    <div>
      <div class="by-mb-10">
        <strong>{{ message.title }}</strong>
      </div>
      <div>
        <span>{{ message.body }}</span>
      </div>
    </div>
  </div>
</ng-template>
