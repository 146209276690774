import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { Message } from '../../models';
import { PushNotificationsActions } from '../../root-store/push-notifications-store';
import { RootState } from '../../root-store/root-state';
import { CloudMessagingService } from '../../services';
import { NotificationBoxComponent } from '../notification-box/notification-box.component';

@Component({
  selector: 'by-notifications-container',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
})
export class NotificationsContainerComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  private subs = new SubSink();

  constructor(
    private cloudMessagingService: CloudMessagingService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private notification: NzNotificationService,
    private store: Store<RootState>,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.cloudMessagingService.getMessages().subscribe((message) => {
        this.renderMessage(message);
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private renderMessage(message: Message) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      NotificationBoxComponent,
    );

    const componentRef = <ComponentRef<NotificationBoxComponent>>(
      this.container.createComponent(factory)
    );

    componentRef.instance.message = message;
    componentRef.changeDetectorRef.detectChanges();

    const notification = this.notification.template(
      componentRef.instance.templateRef,
      { nzDuration: 10000 },
    );

    this.subs.add(
      notification.onClick.pipe(take(1)).subscribe(() => {
        this.store.dispatch(
          PushNotificationsActions.messageClicked({ message }),
        );
      }),
    );

    this.subs.add(
      notification.onClose.pipe(take(1)).subscribe(() => {
        componentRef.destroy();
      }),
    );
  }
}
