import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import {
  Message,
  NotificationTypeFilter,
  PushNotification,
} from '../../models';

export const messagePushed = createAction(
  '[Push Notifications] Message Pushed',
  props<{ message: Message }>(),
);

export const messageClicked = createAction(
  '[Push Notifications] Message Clicked',
  props<{ message: Message }>(),
);

export const loadRequest = createAction(
  '[Push Notifications] Load Request',
  props<{
    page: number;
    notificationsType?: NotificationTypeFilter;
    noLoading?: boolean;
    dateRange?: { date_from: string; date_to: string };
  }>(),
);
export const loadSuccess = createAction(
  '[Push Notifications] Load Success',
  props<{ notifications: PushNotification[]; pagination: IPagination }>(),
);
export const loadFailure = createAction(
  '[Push Notifications] Load Failure',
  props<{ error: any }>(),
);

export const loadCounterRequest = createAction(
  '[Push Notifications] Load Counter Request',
);
export const loadCounterSuccess = createAction(
  '[Push Notifications] Load Counter Success',
  props<{ counter: number }>(),
);
export const loadCounterFailure = createAction(
  '[Push Notifications] Load Counter Failure',
  props<{ error: any }>(),
);

export const flushCounterRequest = createAction(
  '[Push Notifications] Flush Counter Request',
);
export const flushCounterSuccess = createAction(
  '[Push Notifications] Flush Counter Success',
);
export const flushCounterFailure = createAction(
  '[Push Notifications] Flush Counter Failure',
  props<{ error: any }>(),
);

export const toggleReadStatusRequest = createAction(
  '[Push Notifications] Toggle Read Status Request',
  props<{ notificationIds?: string[]; operation: 'set_read' | 'set_unread' }>(),
);
export const toggleReadStatusSuccess = createAction(
  '[Push Notifications] Toggle Read Status Success',
  props<{ notificationIds?: string[]; operation: 'set_read' | 'set_unread' }>(),
);
export const toggleReadStatusFailure = createAction(
  '[Push Notifications] Toggle Read Status Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Push Notifications] Reset State');

export const resetItems = createAction('[Push Notifications] Reset Items');
