import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationBoxComponent } from './notification-box.component';

@NgModule({
  declarations: [NotificationBoxComponent],
  exports: [NotificationBoxComponent],
  imports: [CommonModule],
})
export class NotificationBoxModule {}
