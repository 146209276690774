import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { UiModule } from '../../ui/ui.module';

import { NotificationsListComponent } from './notifications-list.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { DateRangeSelectorModule } from '../date-range-selector/date-range-selector-module';
import { IsAdminDirective } from '../../directives/is-admin.directive';
@NgModule({
  declarations: [NotificationsListComponent],
  exports: [NotificationsListComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    UiModule,
    TranslateModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    NzInputModule,
    DateRangeSelectorModule,
    IsAdminDirective,
  ],
})
export class NotificationsListModule {}
