import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Message } from '../../models';

@Component({
  selector: 'by-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss'],
})
export class NotificationBoxComponent implements OnInit {
  @Input()
  message: Message;

  @ViewChild(TemplateRef)
  templateRef: TemplateRef<{}>;

  constructor() {}

  ngOnInit(): void {}
}
