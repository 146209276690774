import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { PushNotification } from '../../models';

export const featureAdapter = createEntityAdapter<PushNotification>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<PushNotification> {
  counter: number;
  pagination: IPagination;
  loading: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  counter: 0,
  pagination: null,
  loading: false,
  error: null,
});
