import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fromEvent, merge, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { generateSearchQuery } from '../../core/helpers/params-generator';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { openInNewTab } from '../../helpers';
import { PushNotification } from '../../models';
import { PushNotificationsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class PushNotificationsStoreEffects {
  constructor(
    private dataService: PushNotificationsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
  ) {}

  listen$ = createEffect(() =>
    this.dataService.listen().pipe(
      switchMap((message) => {
        return of(fromActions.messagePushed({ message }));
      }),
    ),
  );

  click$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.messageClicked),
      map(({ message }) => {
        const { routingData } = message;

        if (routingData && !routingData.external) {
          this.router.navigate(routingData.route, {
            queryParams: routingData.params,
          });
        }

        if (routingData && routingData.external) {
          openInNewTab(
            `${routingData.route.join('/')}${
              routingData.params
                ? '?' + generateSearchQuery(routingData.params)
                : ''
            }`,
          );
        }

        return fromActions.toggleReadStatusRequest({
          operation: 'set_read',
          notificationIds: [message.notification_id],
        });
      }),
    ),
  );

  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ page, notificationsType, dateRange }) =>
        this.dataService.load(page, notificationsType, dateRange).pipe(
          map(({ data, meta }: IResponseSuccess<PushNotification[]>) => {
            return fromActions.loadSuccess({
              notifications: data,
              pagination: meta.pagination,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    );
  });

  loadCounter$ = createEffect(() =>
    merge(
      this.actions$.pipe(ofType(fromActions.loadCounterRequest)),
      fromEvent(document, 'visibilitychange').pipe(debounceTime(30000)),
    ).pipe(
      filter(() => !document.hidden),
      switchMap(() =>
        this.dataService.load(null, 'count').pipe(
          map(({ data }: IResponseSuccess<{ count: number }>) => {
            return fromActions.loadCounterSuccess({ counter: data.count });
          }),
          catchError((error) => {
            return of(fromActions.loadCounterFailure(error));
          }),
        ),
      ),
    ),
  );

  flushCounter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.flushCounterRequest),
      switchMap(() =>
        this.dataService.flushCounter().pipe(
          map(() => {
            return fromActions.flushCounterSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.flushCounterFailure(error));
          }),
        ),
      ),
    ),
  );

  toggleReadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleReadStatusRequest),
      switchMap(({ operation, notificationIds }) =>
        this.dataService.toggleReadStatus(operation, notificationIds).pipe(
          mergeMap(() => {
            return [
              fromActions.toggleReadStatusSuccess({
                operation,
                notificationIds,
              }),
              fromActions.loadCounterRequest(),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.toggleReadStatusFailure(error));
          }),
        ),
      ),
    ),
  );
}
