import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { NotificationTypeFilter, PushNotification } from '../../models';
import {
  PushNotificationsActions,
  PushNotificationsSelectors,
} from '../../root-store/push-notifications-store';
import { RootState } from '../../root-store/root-state';

import { NotificationsSource } from './notifications-source';
import { FormBuilder } from '@angular/forms';
import { DateFormatterService } from '../../core/services/date-formatter.service';

@Component({
  selector: 'by-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  @Output()
  clickNotification = new EventEmitter();

  @Input() loading: boolean;

  notifications: NotificationsSource;

  showUnread = false;

  dateRange = this.fb.control(null);

  cdnUrl = environment.cdnUrl;

  private subs = new SubSink();

  constructor(
    private router: Router,
    private store: Store<RootState>,
    private fb: FormBuilder,
    private dateFormatter: DateFormatterService,
    @Optional() private modalRef: NzModalRef,
    @Optional() private popoverDirective: NzPopoverDirective,
  ) {
    this.setNotificationsSource();
  }

  ngOnInit() {
    this.store.dispatch(PushNotificationsActions.flushCounterRequest());

    this.subs.add(
      this.store
        .pipe(select(PushNotificationsSelectors.selectLoading))
        .subscribe((loading) => {
          this.loading = loading;
        }),
    );

    this.subs.add(
      this.router.events.subscribe(() => {
        this.modalRef?.close();
        this.popoverDirective?.hide();
      }),
    );
    this.subs.add(
      this.dateRange.valueChanges.subscribe(() => {
        this.setNotificationsSource();
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setNotificationsSource() {
    const filters: {
      notificationsType: NotificationTypeFilter;
      dateRange: { date_from: string; date_to: string };
    } = {
      notificationsType: this.showUnread ? 'get_unread' : null,
      dateRange: {
        date_from: this.dateFormatter.toServerFormat(
          this.dateRange?.value?.[0],
        ),
        date_to: this.dateFormatter.toServerFormat(this.dateRange?.value?.[1]),
      },
    };
    this.notifications?.disconnect();
    this.notifications = new NotificationsSource(this.store, filters);
  }

  onToggleRead(notification: PushNotification) {
    this.store.dispatch(
      PushNotificationsActions.toggleReadStatusRequest({
        notificationIds: [notification.id],
        operation: notification.read_at ? 'set_unread' : 'set_read',
      }),
    );
  }

  onClickNotification({ data }: PushNotification) {
    this.clickNotification.emit();
    this.store.dispatch(
      PushNotificationsActions.messageClicked({ message: data }),
    );
  }

  onMarkAllAsRead() {
    this.store.dispatch(
      PushNotificationsActions.toggleReadStatusRequest({
        operation: 'set_read',
      }),
    );
  }
}
