import { Action, createReducer, on } from '@ngrx/store';

import { cloudMessageMapper } from '../../helpers';

import * as fromActions from './actions';
import * as fromState from './state';
import { featureAdapter } from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state, { noLoading }) => ({
    ...state,
    loading: !noLoading,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(fromActions.loadSuccess, (state, { notifications, pagination }) =>
    featureAdapter.addMany(
      notifications.map((notification) => {
        return {
          ...notification,
          data: cloudMessageMapper({
            ...notification.data,
            notification_id: notification.id,
          }),
        };
      }),
      {
        ...state,
        pagination,
        loading: false,
        error: null,
      },
    ),
  ),

  on(fromActions.messagePushed, (state) => ({
    ...state,
    counter: state.counter + 1,
  })),

  on(fromActions.loadCounterSuccess, (state, { counter }) => ({
    ...state,
    counter,
  })),

  on(fromActions.flushCounterSuccess, (state) => ({
    ...state,
    counter: 0,
  })),

  on(
    fromActions.toggleReadStatusSuccess,
    (state, { notificationIds, operation }) => {
      const idsToUpdate = notificationIds || (state.ids as string[]);

      return featureAdapter.map((notification) => {
        if (!idsToUpdate.includes(notification.id)) {
          return notification;
        }

        return {
          ...notification,
          read_at: operation === 'set_unread' ? null : new Date().toISOString(),
        };
      }, state);
    },
  ),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),

  on(fromActions.resetItems, (state) =>
    featureAdapter.removeAll({
      ...state,
      pagination: fromState.initialState.pagination,
    }),
  ),
);

export function pushNotificationsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
