import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { NotificationTypeFilter, PushNotification } from '../../models';
import {
  PushNotificationsActions,
  PushNotificationsSelectors,
} from '../../root-store/push-notifications-store';
import { RootState } from '../../root-store/root-state';

export class NotificationsSource extends DataSource<PushNotification> {
  private pagination: IPagination;

  private cachedData: PushNotification[] = [];

  private fetchedPages = new Set<number>();

  private complete$ = new Subject<void>();

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private filters?: {
      notificationsType: NotificationTypeFilter;
      dateRange: { date_from: string; date_to: string };
    },
  ) {
    super();
  }

  completed(): Observable<void> {
    return this.complete$.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<PushNotification[]> {
    this.setup(collectionViewer);

    return this.store.pipe(
      select(PushNotificationsSelectors.selectAllItems),
      tap((notifications) => {
        this.cachedData = notifications;
      }),
    );
  }
  disconnect() {
    this.subs.unsubscribe();
    this.store.dispatch(PushNotificationsActions.resetItems());
  }

  get length(): number {
    return this.cachedData?.length;
  }

  private setup(collectionViewer: CollectionViewer): void {
    this.fetchPage(1);

    this.store.dispatch(PushNotificationsActions.resetItems());

    this.subs.add(
      collectionViewer.viewChange
        .pipe(takeUntil(this.complete$))
        .subscribe((range) => {
          if (this.cachedData.length >= this.pagination?.total) {
            this.complete$.next();
            this.complete$.complete();
          } else {
            const endPage = this.getPageForIndex(range.end);
            this.fetchPage(endPage + 1);
          }
        }),
    );

    this.subs.add(
      this.store
        .pipe(select(PushNotificationsSelectors.selectPagination))
        .subscribe((pagination) => {
          this.pagination = pagination;
        }),
    );
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pagination?.per_page || 25);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);
    this.store.dispatch(
      PushNotificationsActions.loadRequest({
        page,
        notificationsType: this.filters.notificationsType,
        noLoading: page > 1,
        dateRange: this.filters.dateRange,
      }),
    );
  }
}
