import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationBoxModule } from '../notification-box/notification-box.module';

import { NotificationsContainerComponent } from './notifications-container.component';

@NgModule({
  declarations: [NotificationsContainerComponent],
  exports: [NotificationsContainerComponent],
  imports: [CommonModule, NotificationBoxModule],
})
export class NotificationsContainerModule {}
